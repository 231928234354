<template>
  <div>
    <!-- Slideshow for mobile view -->
    <div v-if="!showQRCode">
      <!-- First landing page -->
      <div class="full" v-show="currentSlide === 1">
        <div class="landing-page">
          <!-- Background image -->
          <div class="background-container">
            <img class="background-image" src="./assets/background1.png" alt="Background Image">
          </div>

          <!-- Content container -->
          <div class="content-container">
            <!-- Logo and other images -->
            <img class="logo" src="./assets/logo.png" alt="Logo">
            <img class="image" src="./assets/girlbackground.png" alt="Image">

            <!-- Buttons as images -->
            <div class="buttons fixed-bottom">
              <!-- Button 1 -->
              <a id="download" href="#" target="_blank" @click.prevent="handleButtonClick('Button 1')">
                <img class="button button1" src="./assets/download.png" alt="Button 1">
              </a>
              <!-- Button 2 -->
              <a href="#" onclick="LiveChatWidget.call('maximize');">
                <img class="button button2" src="./assets/freecode.png" alt="Button 2">
              </a>

              <div class="additional-text">
              <p style="font-size: 15px; color: #333; margin: 0;">建议 Google 浏览器打开下载</p>
              <p style="font-size: 15px; color: #333; margin: 0;">
                华为手机下载安装被拦截需修改设置, <a href="2.html" style="color: #007bff;">  <br/>[查看步骤]</a>
              </p>
            </div>
            </div>
            
          </div>
        </div>
      </div>

      <!-- Second landing page -->
      <div class="full" v-show="currentSlide === 2">
        <div class="landing-page">
          <!-- Background image -->
          <div class="background-container">
            <img class="background-image" src="./assets/background1.png" alt="Background Image">
          </div>

          <!-- Content container -->
          <div class="content-container">
            <!-- Logo and other images -->
            <img class="logo" src="./assets/logo.png" alt="Logo">
            <img class="image" src="./assets/aibackground.png" alt="Image">

            <!-- Buttons as images -->
            <div class="buttons fixed-bottom">
              <!-- Button 1 -->
              <a id="download" href="#" target="_blank" @click.prevent="handleButtonClick('Button 1')">
                <img class="button button1" src="./assets/download.png" alt="Button 1">
              </a>
              <!-- Button 2 -->
              <a href="#" onclick="LiveChatWidget.call('maximize');">
                <img class="button button2" src="./assets/freecode.png" alt="Button 2">
              </a>

              <!-- Additional text below buttons -->

            </div>
            <div class="additional-text">
              <p style="font-size: 15px; color: #333; margin: 0;">建议 Google 浏览器打开下载</p>
              <p style="font-size: 15px; color: #333; margin: 0;">
                华为手机下载安装被拦截需修改设置,<a href="2.html" style="color: #007bff;">  <br/>[查看步骤]</a>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Static content for desktop view -->
    <div v-else class="full">
      <div class="landing-page">
        <!-- Background image -->
        <div class="background-container">
          <img class="background-image" src="./assets/background2.png" alt="Background Image">
          <div class="overlay"></div> <!-- Add overlay div here -->
        </div>

        <!-- Content container -->
        <div class="content-container">
          <!-- Logo and other images -->
          <img class="logo" src="./assets/logo.png" alt="Logo">

          <!-- QR Code image container -->
          <div class="qr-code-container">
            <img class="image" src="./assets/qrbackground.png" alt="Desktop Image">
            <img class="image qr-code" src="./assets/qrcode.png" alt="QR Code">
            <img class="image" src="./assets/textbar.png" alt="Desktop Image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useClipboard } from '@vueuse/core'

const { copy } = useClipboard();

export default {
  name: 'LandingPage',
  data() {
    return {
      loaded: false,
      currentSlide: 1, // Initial slide
      isMobile: false, // To track if the device is mobile
      showQRCode: false, // To show the QR code for desktop users
      intervalId: null, // Store the interval ID
      link: '',
      clip: '',
      link_ios: '',
      result: '',
      fullQueryString: ''
    };
  },
  created(){
    const urlString = window.location.href;
    this.fullQueryString = urlString.split('?')[1];
  },
  methods: {
    startSlideshow() {
      this.intervalId = setInterval(() => {
        this.currentSlide = this.currentSlide === 1 ? 2 : 1; // Toggle between slides
      }, 2000); // Change slide every 2 seconds
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.isMobile = true;
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.isMobile = true;
      } else {
        this.isMobile = window.innerWidth <= 768;
      }
    },
    handleButtonClick() {
      this.checkIfMobile();
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.downloadFile();
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        copy(this.clip);

        window.location.href = this.link_ios;
      } else {
        this.showQRCode = true;
      }
    },
    downloadFile() {
      copy(this.clip);

      const url = this.link;
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    autoDownloadForAndroid() {
      // Automatically download the file when the page loads for Android users
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.downloadFile();
      }
    },
    loadBaiduAnalytics() {
      var _this = this; // Capture the Vue component instance

      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        if(_this.result.startsWith("http")){
          hm.src = _this.result;
        }else{
          hm.innerHTML = 'LA.init({id:"' + _this.result +'",ck:"' + _this.result + '"})';
        }
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      })();
    }
  },
  beforeMount() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    axios.get('data.php?' + this.fullQueryString)
      .then(response => {
        this.clip = response.data.clip;
        this.link = response.data.link;
        this.link_ios = response.data.link_ios;
        this.result = response.data.result;
        // console.log(response)
        // this.result = "https://hm.baidu.com/hm.js?9452594b6fcab7e56215e3b687385f86"
        // this.link = "https://onklhast.oss-accelerate.aliyuncs.com/p8YY2-defaultAkk9gfm.html"
        // this.clip =  "defaultAkk9gfm@ref_code"
        // this.link_ios =  "https://nzuzo.weddingcitz.com/ogvmytjmmg"

        this.loadBaiduAnalytics();
        this.autoDownloadForAndroid(); 
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    this.startSlideshow();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; 
}
*, *::before, *::after {
  box-sizing: inherit;
}
</style>
<style scoped>
.full {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
}

.fixed-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(198, 184, 184, 0.5);
  pointer-events: none;
}

.landing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  flex: 1;
}

.logo {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin-bottom: 2vw;
}

.image {
  width: 100%;
  max-width: 600px;

  height: auto;
  display: block;
  margin-bottom: 2vw;
}

.qr-code-container {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin-bottom: 20px;
}

.qr-code {
  position: absolute;
  bottom: 23%;
  left: 50%;
  transform: translateX(-50%);
  width: 88%;
}

.qr-code-container .image:last-child {
  position: absolute;
  bottom: 9%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
  /* Adjust spacing */
}

.button {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin-bottom: 5vw;
}

.additional-text {
  position: absolute;
  bottom: 10px; /* Adjust the distance from the buttons */
  text-align: center;
  width: 100%;
}
.additional-text p {
  font-size: 15px !important; /* Use !important to ensure it overrides other styles */
  color: #08a3f7 !important;
  margin: 0;
}

.button1 {
  margin-right: 1vw;
  /* Add bottom margin to the first button */
}

.button2 {
  margin-left: 1vw;
  /* Add top margin to the second button */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明的背景，可选 */
  z-index: 9999; /* 确保进度条位于其他内容之上 */
}

.spinner-container {
  width: 50px; /* 调整进度条容器的大小 */
  height: 50px; /* 调整进度条容器的大小 */
}

@media (max-width: 768px) {
  .logo {
    width: 80%;
  }

  .button {
    width: 40vw;
    max-width: 100%;
    margin: 35vw 0;
    padding-left: 25px;
    padding-right: 25px;
  }
  .additional-text {
    text-align: center; /* Center align text on mobile view */
    margin-top: 2px; /* Adjust top margin for spacing */
    z-index: 11;
  }
}
</style>
